import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";

import heroImage from '../../assets/smartsheet-silver-partner.png'; // Adjust the path accordingly.


function HeroSection() {
    return (
        <section style={{ padding: '100px 0' }}>
            <Grid container spacing={4} alignItems="center">

                {/* Text/Grid on the left */}
                <Grid item xs={12} md={6} style={{ paddingLeft: '150px', paddingRight: '100px' }}>
                    <Typography variant="h4" gutterBottom style={{color: "#f39e28"}}>
                        OPTIMIZE YOUR PROJECT MANAGEMENT STRATEGY
                    </Typography>
                    <Typography variant="h6" paragraph>
                        Smartsheet stands as the paramount platform for online work orchestration and team synergy. It emerges as the strategic selection for diverse projects due to its versatility in fostering expedited workflow and enhancing organizational output across varying scales.
                        <br /> <br />
                        This platform facilitates real-time strategic planning, execution, and monitoring of your initiatives, coupled with the ability to automate repetitive operational sequences and generate insightful reports, all scalable to enterprise demands.
                        <br /> <br />
                        Implementing Smartsheet across your enterprise is a streamlined affair. Its integration capabilities span a broad spectrum of enterprise solutions.
                        <br /> <br />
                        By centralizing project components, Smartsheet not only amplifies collaborative endeavors across divisions but also catalyzes decision-making processes. The automation feature negates the need for manual redundancies, leading to a significant annual time economy.
                        <br /> <br />
                        Emphasizing its robust nature, Smartsheet ensures data protection with enterprise-grade security standards. Its credibility is further endorsed by millions of professionals across many Fortune 500s, who entrust their operational nuances to Smartsheet.
                    </Typography>
                </Grid>

                {/* Image/Grid on the right */}
                <Grid item xs={12} md={6}>
                    <img src={heroImage} alt="Hero" style={{ width: '75%', height: 'auto' }} />
                </Grid>

            </Grid>
        </section>
    );
}

export default HeroSection
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";

import heroImage from '../../assets/home-expertise-img.png'; // Adjust the path accordingly.


function ExpertiseInAction() {
    return (
        <section style={{ padding: '100px 0' }}>
            <Grid container spacing={4} alignItems="center">

                <Grid item xs={12} md={6}>
                    <img src={heroImage} alt="Hero" style={{ width: '100%', height: 'auto' }} />
                </Grid>

                <Grid item xs={12} md={6} style={{ paddingLeft: '150px', paddingRight: '100px' }}>
                    <Typography variant="h4" gutterBottom style={{color: "#f39e28"}}>
                        Expertise In Action
                    </Typography>
                    <Typography variant="h6" paragraph>
                        We go beyond providing guidance and best practices about how to do things and <b>deliver actual solutions</b> that gets the job done.
                        <br /><br />
                        Our focus is taking your complex business problems and establishing technical solutions around them to remove the swivel chair processes that might be in place today.
                        <br /><br />
                        Let's have a quick conversation and see how we can improve your current business and solutions.
                    </Typography>
                </Grid>

            </Grid>
        </section>
    );
}

export default ExpertiseInAction
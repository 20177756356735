import React from 'react';

const Index = () => {
    return (
        <div className="privacy-policy-container" style={{marginTop: 100}}>
            <h2>GymScribe Mobile App - Privacy Policy</h2>
            <p><strong>Effective Date:</strong> 10/02/2023</p>

            <h3>1. Introduction</h3>
            <p>Welcome to GymScribe ("we", "our", or "us"). Our primary objective is to provide users ("you" or "user") with an efficient platform to record and manage their workouts. This Privacy Policy outlines the type of information we collect, how we use it, and the choices you have regarding your data.</p>

            <h3>2. Information We Collect</h3>
            <ul>
                <li><strong>2.1 Personal Information:</strong> We do not intentionally collect personal information. Any personal information entered by the user, such as name, email, or contact details, remains the sole responsibility of the user.</li>
                <li><strong>2.2 Workout Data:</strong> Users can input their workout routines, progress, and related notes. This data remains the user's responsibility and is stored for user convenience only.</li>
            </ul>

            <h3>3. User's Responsibility</h3>
            <p>By using GymScribe:</p>
            <ul>
                <li><strong>3.1</strong> You acknowledge that you are solely responsible for the information and content you input or upload.</li>
                <li><strong>3.2</strong> You agree to ensure that all personal and sensitive information entered in the app is accurate and updated regularly.</li>
                <li><strong>3.3</strong> You understand and accept that while we strive to keep your data secure, we cannot guarantee absolute security. It remains your responsibility to keep backups of your data and protect your device.</li>
            </ul>

            <h3>4. Usage of Data</h3>
            <ul>
                <li><strong>4.1</strong> GymScribe does not use your data for advertising, marketing, or any third-party affiliations.</li>
                <li><strong>4.2</strong> We use the data solely to improve the functionality of the app and to enhance user experience.</li>
            </ul>

            <h3>5. Apple App Store Terms</h3>
            <p>GymScribe adheres to the standard terms and conditions set by Apple's App Store for both the distribution and usage of mobile apps. By downloading GymScribe from the App Store, you also agree to Apple's standard terms and conditions.</p>

            <h3>6. Changes to the Privacy Policy</h3>
            <p>From time to time, we may update this Privacy Policy to reflect changes in our practices or for other operational, legal, or regulatory reasons. If we make changes, we will notify users through an in-app notification or other means.</p>

            <h3>7. Contact</h3>
            <p>For more information about our privacy practices, questions, or if you would like to make a complaint, please contact us at ryan@dynamicduo.io.</p>

            <h3>8. Acceptance of Terms</h3>
            <p>By using GymScribe, you signify your acceptance of this Privacy Policy. If you do not agree to this policy, please do not use our app.</p>
        </div>
    );
}

export default Index;

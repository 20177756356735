import React from "react"; // Adjust the path accordingly.

import HeroSection from "../Smartsheet/HeroSection";
import FeatureSection from "../Home/FeaturesSection";
import ExpertiseInAction from "../Home/ExpertiseInAction";
import OurStrengths from "./OurStrengths";
import BlogPost from "./BlogPost";

function Index() {
    return (
            <>
                <HeroSection />
                <OurStrengths />
                <BlogPost />
            </>
    );
}

export default Index
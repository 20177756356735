import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import HandshakeIcon from '@mui/icons-material/Handshake';

import WorkIcon from '@mui/icons-material/Work';
import GroupIcon from '@mui/icons-material/Group';
import SettingsIcon from '@mui/icons-material/Settings';
import BuildIcon from '@mui/icons-material/Build';

import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {Box} from "@mui/system";



function OurStrengths() {
    const items = [
        { icon: <VerifiedUserIcon fontSize="large" />, header: "Certified", details: "We know the product very well and we also know its limitations and how to overcome them in multiple different ways." },
        { icon: <WorkIcon fontSize="large" />, header: "Continuously Deliver", details: "We have been building technical solutions on top of Smartsheet since 2019." },
        { icon: <HandshakeIcon fontSize="large" />, header: "Collaboration", details: "Only you know your business as well as you do. We excel in working with you to close the gaps in your current solutions." },
        { icon: <SettingsIcon fontSize="large" />, header: "Developers", details: "We specialize in providing technical solutions on top of Smartsheet solutions." }
    ];
    return (
        <Box mx={5}>
            <Grid container spacing={3} >
                {items.map((item, index) => (
                    <Grid item xs={3} key={index}>
                        <Paper style={{ textAlign: 'center', padding: '20px', minHeight: 180, backgroundColor:"#eaeaee" }}>
                            {item.icon}
                            <h3>{item.header}</h3>
                            <p>{item.details}</p>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}

export default OurStrengths
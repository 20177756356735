// Header.js
import React, { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { Box } from '@mui/system';
import logoImage from '../assets/logo.png';
import { useNavigate } from 'react-router-dom';


function Header() {
    const navigate = useNavigate();

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            // Check if the user has scrolled
            if (window.scrollY > 0) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Cleanup - Remove the event listener when the component is unmounted
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []); // Empty dependency array to ensure this useEffect runs only once (similar to componentDidMount)

    return (
        <AppBar
            position="fixed"
            sx={{
                backgroundColor: isScrolled ? '#ffffff' : 'transparent',
                transition: 'background-color 0.3s ease', // Smooth transition for background color change
                boxShadow: 'none',
                borderBottom: isScrolled ? '1px solid #ccc' : 'none'
            }}
        >
            <Toolbar style={{ alignItems: 'center' }}>
                <Box
                    display="flex"
                    flexGrow={1}
                    alignItems="center"
                    onClick={() => navigate('/')}
                    style={{ cursor: 'pointer' }}  // to indicate that the box is clickable
                >
                    <img src={logoImage} alt="Company Logo" style={{ height: '40px', marginRight: '10px' }} />
                </Box>

                <Button
                    color="inherit"
                    sx={{ color: '#000' }}
                    onClick={() => navigate('/smartsheet')}
                >
                    Smartsheet
                </Button>
                {/*<Button*/}
                {/*    color="inherit"*/}
                {/*    sx={{ color: '#000' }}*/}
                {/*    onClick={() => navigate('/contact')}*/}
                {/*>*/}
                {/*    Let's Chat*/}
                {/*</Button>*/}

                {/*<Button color="inherit" sx={{ color: '#000' }}>Products</Button>*/}
                {/*<Button color="inherit" sx={{ color: '#000' }}>Services</Button>*/}
                {/*<Button color="inherit" sx={{ color: '#000' }}>Contact Us</Button>*/}
            </Toolbar>
        </AppBar>
    );
}

export default Header;
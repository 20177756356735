import CaseStudyOne from "./CaseStudyOne";

function CaseStudies(props) {
    return (
        <section style={{ padding: '100px 0' }}>
            {
                props.case === 'smartsheet-to-fullstack' &&
                <CaseStudyOne />

            }
        </section>
    );
}

export default  CaseStudies
import React from 'react';

function CaseStudyOne() {
    return (
        <div style={{ margin: '2rem', fontFamily: 'Arial, sans-serif' }}>
            <h1 style={{ textAlign: 'center' }}>
                Enhancing Business Efficiency: Transition from Smartsheet to a Tailored Full-Stack Application
            </h1>

            <section style={{ marginTop: '1.5rem' }}>
                <h2>Executive Summary</h2>
                <p>
                    This case study details the transformational journey of converting an existing Smartsheet-based workflow into a bespoke full-stack application designed to streamline business operations. The newly developed system boasts a user-friendly interface, a robust service layer, and a secure database, all tailored to meet the unique needs of the client. We implemented automated pipelines to handle complex business logic and background processes efficiently, ensuring seamless day-to-day operations. The solution was successfully deployed on an on-premise cloud platform, enhancing data accessibility and security. The application features multiple user views, catering to the diverse needs of stakeholders, from frontline employees to executive management. This customization was critical in providing a personalized experience and driving user adoption. Through this transition, the client saw significant improvements in process efficiency, data management, and overall business agility, demonstrating the power of customized technological solutions in solving complex business challenges.
                </p>
            </section>

            <section style={{ marginTop: '1.5rem' }}>
                <h2>Background Information</h2>
                <p>
                    At the heart of this case study lies Indiana Michigan Power, a utility company grappling with the complexities of modernizing its reporting and legal process management. In an environment where precision and accountability are paramount, the company recognized the urgent need to overhaul its decentralized system. Traditionally, case managers relied on an assortment of personal productivity tools—from sticky notes and digital OneNotes to isolated offline documents—resulting in a fragmented process landscape.
                    This disjointed approach not only obscured management’s view of operations but also heightened the risk of information silos and process bottlenecks. Critical to the company's operational integrity was the need to ensure continuity and clarity in case management, with some cases teetering on the brink of non-compliance due to opaque ownership and erratic timelines. The leaders were in dire need of a unified platform that could not just consolidate case information for enhanced visibility but also propel cases toward resolution with greater efficiency.
                    With the transformation imperative clear, the aim was to create a centralized repository that would not only house all case-related information but also allow for dynamic oversight and agile response by leadership in any situation. This case study chronicles the journey from scattered notes and documents to a cohesive, streamlined process that fortifies Indiana Michigan Power’s commitment to rigorous reporting and legal compliance.
                </p>
            </section>

            <section style={{ marginTop: '1.5rem' }}>
                <h2>Problem Statements</h2>
                <ul>
                    <li>Decentralized Case Management: Case managers employed a variety of personal methods for managing cases, such as sticky notes, OneNote, and offline documents, leading to a lack of a centralized system.</li>
                    <li>Inconsistent Information Tracking: The absence of a unified tracking system meant that the status of various reporting and legal activities was difficult to ascertain and manage effectively.</li>
                    <li>Limited Management Visibility: Management lacked a clear overview of processes, as information was scattered across individual activity owners without a consolidated view.</li>
                    <li>Risk of Information Siloing: With case managers holding critical information individually, there was a significant risk of knowledge silos, which could impact the ability to substitute personnel and maintain continuity.</li>
                    <li>Challenges in Continuity and Accountability: The reliance on individual case managers for information posed risks for continuity in case management, especially if a key individual was unavailable.</li>
                    <li>Inefficiency in Case Resolution: The current process was not conducive to timely case resolution, with management needing to compile details from various sources to understand case status.</li>
                    <li>Need for Centralized Status Updates: There was a critical requirement for a system that could provide real-time status updates on each case to leadership to help drive processes and resolve cases more promptly.</li>
                </ul>
            </section>

            <section style={{ marginTop: '1.5rem' }}>
                <h2>Implementation and Results</h2>
                <p>
                    Our approach to transforming Indiana Michigan Power’s operational ecosystem began with a meticulous ingestion of the disparate data from the various existing sheets. This foundational step was designed to centralize the fragmented information, thereby creating a single source of truth for reporting on all activities. We engineered this ingestion process to be not just thorough but also flexible, allowing for daily updates or more frequent synchronization as needed.
                </p>
                <p>
                    Upon this bedrock of consolidated data, we architected a suite of services that provided multi-dimensional insights into the workflow. These services were crafted to answer critical operational questions, such as the distribution of activity ownership between the Operating Company and Activity Managers, the current status of activities, and recent updates like notes and status changes. By doing so, we ensured that any stakeholder could gain a snapshot of the operational pulse at any given moment.
                </p>
                <p>
                    The services also enabled intricate interaction with the data, allowing updates and modifications to be made directly within the system. This was a vital feature to maintain the dynamism required in the ever-evolving landscape of case management.
                </p>
                <p>
                    Parallel to the development of these services, we were constructing a user interface—a website that served as the gateway for business users and management to engage with the data intuitively. This interface was the linchpin in our strategy to provide seamless interaction with the system, ensuring that user adoption was as natural as it was swift.
                </p>
                <p>
                    Upon the completion of the Minimum Viable Product (MVP), we achieved a significant milestone: the decommissioning of the original data sources. The entire lifecycle of an activity, from initiation to closure, was now fully managed within the ambit of our custom-developed tool. The results were as immediate as they were impactful:
                </p>
                <ul>
                    <li>Centralization of Data: Achieved a unified data environment, increasing efficiency and reducing the risk of errors.</li>
                    <li>Enhanced Visibility: Provided management with real-time visibility into all activities, improving oversight and decision-making.</li>
                    <li>Streamlined Processes: Enabled a more streamlined process flow, significantly reducing the case resolution times.</li>
                    <li>Improved Continuity: Ensured continuity in case management, with the ability to easily substitute personnel as needed.</li>
                    <li>User Adoption: Successfully facilitated the transition of all users to the new system, with minimal disruption to daily operations.</li>
                </ul>
                <p>
                    The transition to this custom application proved to be a game-changer for Indiana Michigan Power, heralding a new era of efficiency, transparency, and control in managing legal and reporting activities.
                </p>
            </section>
        </div>
    );
}

export default CaseStudyOne;

import featuredOne from "../../assets/home-featured-img1.png";
import featuredTwo from "../../assets/home-featured-img2.png";
import featuredThree from "../../assets/home-featured-img3.png";

function FeaturesSection() {
    return (
        <section style={{ padding: '100px 0', textAlign: 'center' }}>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '50px' }}>
                <div style={{ margin: '0 40px' }}>

                <img src={featuredOne} alt="Facilitating Innovation" style={{ width: '10', height: '10' }} />
                    <h1 style={{color: "#f39b03"}}>Facilitating Innovation</h1>
                    <h3>Developed an application that enabled for judging of events and recording results for historical reporting.</h3>
                </div>
                <div style={{ margin: '0 40px' }}>

                <img src={featuredTwo} alt="Enabling Change" style={{ width: '10', height: '10' }} />
                    <h1 style={{color: "#f39b03"}}>Enabling Change</h1>
                    <h3>Developed applications that improved the internal process of recording and approving changes.</h3>
                </div>
                <div style={{ margin: '0 40px' }}>

                <img src={featuredThree} alt="Software Development" style={{ width: '10', height: '10' }} />
                    <h1 style={{color: "#f39b03"}}>Software Development</h1>
                    <h3>Developed user-defined business applications that could be run on users systems.</h3>
                </div>
            </div>
        </section>
    );
}

export default  FeaturesSection
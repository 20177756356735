import React from "react"; // Adjust the path accordingly.

import HeroSection from "./HeroSection";
import FeatureSection from "./FeaturesSection";
import ExpertiseInAction from "./ExpertiseInAction";
import CaseStudies from "./CaseStudies";

function Index() {
    return (
            <>
                <HeroSection />
                <FeatureSection />
                <ExpertiseInAction />
                <CaseStudies />
            </>
    );
}

export default Index
import {Grid, Typography} from "@mui/material";
import Button from "@mui/material/Button";

import heroImage from '../../assets/SmartsheetLogo.jpeg'; // Adjust the path accordingly.


function HeroSection() {
    return (
        <section style={{ padding: '100px 0' }}>
            <Grid container spacing={4} alignItems="center">
                <Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <Typography variant={"h4"}>Do You Want to <b>Automate Your Business?</b></Typography>
                    <Typography variant={"h6"}>We're looking for great business partners who want to dramatically decrease manual work in their business. Are you ready for the change?</Typography>
                </Grid>


                {/* Text/Grid on the left */}
                <Grid item xs={12} md={6} style={{ paddingLeft: '150px', paddingRight: '100px' }}>
                    <Typography variant="h4" gutterBottom style={{color: "#f39e28"}}>
                        Leading in Dynamic Solutions For An Ever Changing World
                    </Typography>
                    <Typography variant="h6" paragraph>
                        We build everything from custom integrations, full-suite applications, machine learning models and architecture design utilizing best practices. Schedule a meeting with us and let us show you how we can make your business even more dynamic.
                    </Typography>
                </Grid>

                {/* Image/Grid on the right */}
                <Grid item xs={12} md={6}>
                    <img src={heroImage} alt="Hero" style={{ width: '100%', height: 'auto' }} />
                </Grid>

            </Grid>
        </section>
    );
}

export default HeroSection
import featuredOne from "../../assets/case-study-IaM.png";
import {Typography} from "@mui/material";
import {Link} from "react-router-dom"

function CaseStudyCard({ image, title, summary, link }) {
    return (
        <Link to={link} style={{ textDecoration: 'none', color: 'inherit' }}>
            <div style={{ width: '400px', margin: '40px', padding: '0 0 20px 0', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)', background: 'white' }}>
                <img src={image} alt={title} style={{ width: '100%', height: '200px', objectFit: 'cover' }} />
                <div style={{ padding: '0 10px' }}>
                    <h4>{title}</h4>
                    <p style={{ marginBottom: '20px' }}>{summary}</p>
                </div>
            </div>
        </Link>
    );
}

function CaseStudies() {
    return (
        <section style={{ padding: '100px 0', textAlign: 'left', marginLeft: 50 }}>
            <Typography variant="h4" gutterBottom style={{color: "#f39e28"}}>
                Case Studies
            </Typography>
            <p>Discover how we are transforming businesses with innovative solutions.</p>
            <div className="case-studies-container" style={{ display: 'flex', justifyContent: 'left', alignItems: 'left', flexWrap: 'wrap', marginTop: '50px' }}>
                {/* Each CaseStudyCard component represents a case study, with its own image and summary */}
                <CaseStudyCard
                    image={featuredOne}
                    title="Smartsheet to Full-Stack Application"
                    summary="Transitioning from fragmented processes to a seamless full-stack application, we enhanced operational efficiency and data visibility for Indiana Michigan Power."
                    link={"/case-studies/smartsheet-to-fullstack"}
                />
                {/*<CaseStudyCard*/}
                {/*    image={featuredTwo}*/}
                {/*    title="Title for Second Case Study"*/}
                {/*    summary="A brief summary of the second case study highlighting major accomplishments and outcomes."*/}
                {/*/>*/}
                {/*<CaseStudyCard*/}
                {/*    image={featuredThree}*/}
                {/*    title="Title for Third Case Study"*/}
                {/*    summary="A brief summary of the third case study emphasizing the challenges faced and solutions provided."*/}
                {/*/>*/}
                {/* Add more CaseStudyCard components as needed */}
            </div>
        </section>
    );
}

export default  CaseStudies
import logo from './logo.svg';
import './App.css';
import React, {useEffect, } from 'react';
import Header from './components/Header';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Index from "./pages/Home";
import Smartsheet from "./pages/Smartsheet";
import Contact from "./pages/Contact";
import ReactGA from "react-ga4";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CaseStudyDetails from "./pages/CaseStudyDetails";

const TRACKING_ID = "G-Z9Q2L8X9FH";
ReactGA.initialize([
    {
        trackingId: TRACKING_ID,
        // gaOptions: {...}, // optional
        // gtagOptions: {...}, // optional
    }
]);

function Analytics() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: `${location.pathname} + ${location.search}`});
        }, [location]);

    return null;
}

function App() {

    return (
        <Router>
            <div>
                <Analytics />
                <Header/>
                <Routes>
                    <Route path="/" element={<Index/>}/>
                    <Route path="/smartsheet" element={<Smartsheet/>}/>
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/case-studies/smartsheet-to-fullstack" element={<CaseStudyDetails case={"smartsheet-to-fullstack"}/>} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;

import React, { useEffect } from "react";
import {Grid} from "@mui/material";

function Index() {

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js";
        script.type = "text/javascript";
        document.body.appendChild(script);

        // Clean up on component unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);  // Empty dependency array means this useEffect runs once when the component mounts.

    return (<></>
            // <div style={{marginTop: 100}} className="meetings-iframe-container" data-src="https://meetings.hubspot.com/ryantheba?embed=true"></div>

    );
}

export default Index;
